<template>
  <div class="items-center flex flex-col">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
        <transition name="slide-fade">
          <div
            key="1"
            v-if="notification_data.info && !n_loading"
            class="bg-yellow-100 rounded-lg py-4 px-6 mb-2 text-sm text-yellow-700 inline-flex justify-between items-center w-full"
          >
            <span
              ><strong class="mr-1">{{ notification_data.fullname }}</strong
              >{{
                notification_data.info == "enroll"
                  ? "needs to be enrolled to his next course."
                  : "Something else"
              }}
            </span>
            <button
              type="button"
              @click="closeNotification"
              class="inline-block px-3 py-1 border-2 border-yellow-500 text-yellow-500 font-medium text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            >
              Done
            </button>
          </div>
        </transition>
        <transition name="slide-fade">
          <span
            key="2"
            v-if="!notification_data.info && !n_loading"
            class="font-medium text-sm py-4 px-3 mb-2"
            >Welcome <strong class="mr-1">{{ this.user }}</strong></span
          >
        </transition>
        <div class="border overflow-hidden">
          <div class="bg-gray-800">
            <h3 class="p-9 text-center text-white">{{ header }}</h3>
            <h5
              style="cursor: pointer"
              v-if="new_data"
              @click="updateData"
              class="text-sm font-light text-center text-white"
            >
              Click Here To Update Submissions List
            </h5>
          </div>
          <table class="min-w-full text-center">
            <thead class="border-b bg-gray-800">
              <tr>
                <th
                  scope="col"
                  class="text-sm font-medium text-white px-6 py-4"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-white px-6 py-4"
                >
                  Course
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-white px-6 py-4"
                >
                  Activity
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-white px-6 py-4"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody id="pending-table">
              <tr class="bg-green-500" v-if="quiz_data.length">
                <td
                  class="text-left text-sm text-black font-light px-6 py-4"
                  colspan="4"
                >
                  Quiz Notifications
                </td>
              </tr>
              <tr
                v-for="(item, key) in quiz_data"
                :key="key"
                class="bg-white border-b"
              >
                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                  <a
                    :href="
                      'moodlemobile://https://learn.earlystemer.com?&redirect=https://learn.earlystemer.com/message/index.php?id=' +
                      item[1]
                    "
                    target="_blank"
                    v-if="isMobile()"
                    >{{ item[0] }}
                  </a>
                  <a
                    :href="
                      'https://learn.earlystemer.com/message/index.php?id=' +
                      item[1]
                    "
                    target="_blank"
                    v-else
                    >{{ item[0] }}
                  </a>
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  {{ item[2].replaceAll("+", " ") }}
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  <a
                    :href="
                      'https://learn.earlystemer.com/mod/quiz/overrides.php?cmid=' +
                      item[3] +
                      '&mode=user'
                    "
                    target="_blank"
                    >End of Course - End of Course Quiz
                  </a>
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  <div class="flex justify-center">
                    <div>
                      <select
                        v-model="item[4]"
                        name="status"
                        class="form-select appearance-none block w-full px-3 py-1.5 text-center font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example"
                      >
                        <option value="Pending" selected>Pending</option>
                        <option value="Handled">Handled</option>
                        <option value="Special Case">Special Case</option>
                      </select>
                    </div>
                  </div>
                </td>
                <input type="hidden" name="userid" :value="item[1]" />
              </tr>
              <tr class="bg-sky-500" v-if="project_data.length">
                <td
                  class="text-left text-sm text-black font-light px-6 py-4"
                  colspan="4"
                >
                  Pending Submissions
                </td>
              </tr>
              <tr
                v-for="(item, key) in project_data"
                :key="key"
                class="bg-white border-b"
              >
                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                  <a
                    :href="
                      'moodlemobile://https://learn.earlystemer.com?&redirect=https://learn.earlystemer.com/message/index.php?id=' +
                      item[1]
                    "
                    target="_blank"
                    v-if="isMobile()"
                    >{{ item[0] }}
                  </a>
                  <a
                    :href="
                      'https://learn.earlystemer.com/message/index.php?id=' +
                      item[1]
                    "
                    target="_blank"
                    v-if="!isMobile()"
                    >{{ item[0] }}
                  </a>
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  {{ item[2].replaceAll("+", " ") }}
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  <a
                    :href="
                      'https://learn.earlystemer.com/mod/assign/view.php?id=' +
                      item[5] +
                      '&rownum=0&action=grader&userid=' +
                      item[1]
                    "
                    target="_blank"
                    >{{ item[4] }}
                  </a>
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4">
                  <div class="flex justify-center">
                    <div>
                      <select
                        v-model="item[6]"
                        name="status"
                        class="form-select appearance-none block w-full px-3 py-1.5 text-center font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example"
                      >
                        <option value="Not graded" selected>Not graded</option>
                        <option value="Sent a message">Sent a message</option>
                        <option value="Left for tutor">Left for tutor</option>
                      </select>
                    </div>
                  </div>
                </td>
                <input type="hidden" name="userid" :value="item[1]" />
                <input type="hidden" name="courseid" :value="item[3]" />
                <input
                  type="hidden"
                  name="contextinstanceid"
                  :value="item[5]"
                />
              </tr>
            </tbody>
            <tfoot>
              <tr class="bg-white border-b">
                <td
                  colspan="4"
                  class="text-sm text-gray-900 font-light px-6 py-4"
                >
                  <div class="flex justify-center items-center">
                    <div>
                      <div
                        style="border-top-color: transparent"
                        v-show="loading"
                        class="w-8 h-8 border-4 border-gray-800 border-dotted rounded-full animate-spin"
                        role="status"
                      ></div>
                      <div id="save_button" v-show="!loading">
                        <input
                          :title="
                            new_data ? 'Update submissions list first' : ''
                          "
                          style="cursor: pointer"
                          :disabled="new_data"
                          @click="submitData"
                          v-if="project_data.length || quiz_data.length"
                          v-bind:class="
                            new_data
                              ? 'text-white text-sm font-medium bg-gray-300 px-4 py-1.5 border border-solid border-gray-300 text-center rounded'
                              : 'text-white text-sm font-medium bg-gray-800 px-4 py-1.5 border border-solid border-gray-300 text-center rounded'
                          "
                          type="submit"
                          value="Save"
                        />
                        <span v-else>Nothing pending</span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from "@firebase/auth";
export default {
  name: "HomeView",
  data: function () {
    return {
      user: null,
      polling: null,
      header: "Action Table",
      loading: false,
      n_loading: false,
      new_data: false,
      project_data: [],
      quiz_data: [],
      notification_data: "",
      old_project_data: "",
      old_quiz_data: "",
      url: "https://script.google.com/macros/s/AKfycbygNhs_h54rKLVW-QHs-csT3BLb7OYs6irL-4uOgtlcM5EPPZm4TeY95Ia_rwBNVe1jBA/exec",
    };
  },
  mounted() {
    this.user = getAuth().currentUser.displayName;
    this.loading = true;
    this.fetchData(true);
    this.pollData();
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  methods: {
    fetchData: function (notify) {
      fetch(this.url)
        .then((res) => res.json())
        .then((data) => {
          this.project_data = data.projects;
          this.old_project_data = JSON.stringify(data.projects);
          this.quiz_data = data.quizzes;
          this.old_quiz_data = JSON.stringify(data.quizzes);
          if (notify) {
            this.notification_data = data.notification;
          }
          this.loading = false;
        });
    },
    postData: function (type, end_load = false) {
      if (type == "project") {
        fetch(this.url, {
          method: "post",
          body: JSON.stringify({
            projects: this.project_data,
            tutor: this.user,
            type: "update_project",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.project_data = data;
            this.old_project_data = JSON.stringify(data);
            if (end_load) {
              this.loading = false;
            }
          });
      } else if (type == "quiz") {
        fetch(this.url, {
          method: "post",
          body: JSON.stringify({
            quizzes: this.quiz_data,
            tutor: this.user,
            type: "update_quiz",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.quiz_data = data;
            this.old_quiz_data = JSON.stringify(data);
            if (end_load) {
              this.loading = false;
            }
          });
      } else if (type == "notification") {
        fetch(this.url, {
          method: "post",
          body: JSON.stringify({
            notifications: this.notification_data,
            tutor: this.user,
            type: "complete_notification",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.notification_data = data;
            this.n_loading = false;
          });
      }
    },
    submitData: function () {
      this.loading = true;
      this.postData("project", false);
      this.postData("quiz", true);
    },
    pollData: function () {
      this.polling = setInterval(() => {
        fetch(this.url)
          .then((res) => res.json())
          .then((data) => {
            if (JSON.stringify(data.projects) != this.old_project_data) {
              if (!this.loading) {
                this.new_data = true;
              }
            }
            if (JSON.stringify(data.quizzes) != this.old_quiz_data) {
              if (!this.loading) {
                this.new_data = true;
              }
            }
            if (!this.notification_data.info) {
              this.notification_data = data.notification;
            }
          });
      }, 5000);
    },
    updateData: function () {
      this.loading = true;
      this.new_data = false;
      this.project_data = [];
      this.quiz_data = [];
      this.fetchData(false);
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    closeNotification: function () {
      this.n_loading = true;
      this.notification_data.status = "Closed";
      this.postData("notification");
    },
  },
  components: {},
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
