import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDLLlgox_N9J_oFYA2gWG1w4Yz_ZCGPw4s",
  authDomain: "tutor-dashboard-db8ae.firebaseapp.com",
  projectId: "tutor-dashboard-db8ae",
  storageBucket: "tutor-dashboard-db8ae.appspot.com",
  messagingSenderId: "31026269493",
  appId: "1:31026269493:web:c1d868b843d1b80c6a107c",
};

initializeApp(firebaseConfig);

createApp(App).use(router).mount("#app");
