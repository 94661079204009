<template>
  <div class="min-h-screen bg-slate-400 w-full py-16 px-4">
    <div class="flex flex-col items-center justify-center">
      <div
        class="bg-gray-800 shadow rounded lg:w-1/4 md:w-1/2 w-full p-10 py-16 mt-16"
      >
        <img
          src="../assets/earlystemer-logo.png"
          style="width: 50%"
          alt="logo"
          class="mx-auto pb-10"
        />
        <p
          tabindex="0"
          class="focus:outline-none text-2xl font-extrabold leading-6 text-white"
        >
          Login to your account
        </p>
        <p v-if="error" class="pt-5 font-medium text-white">
          Something went wrong
        </p>
        <button
          @click="signInWithGoogle"
          aria-label="Continue with google"
          role="button"
          class="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-white py-3.5 px-4 border rounded-lg border-white-700 flex items-center w-full mt-10"
        >
          <img src="../assets/sign_in-svg2.svg" alt="google" />
          <p class="text-base font-medium ml-4 text-white">
            Continue with Google
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
export default {
  name: "AuthView",
  data: function () {
    return {
      router: useRouter(),
      error: false,
    };
  },
  methods: {
    signInWithGoogle: function () {
      const provider = new GoogleAuthProvider();
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          console.log(result);
          this.router.push("/");
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>
